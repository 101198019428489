import { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { formatDate } from '../../../utils/date-formatters';
import {
    formatExternalTeamContact,
  formatInternalContactData,
  formatInternalTeamContact,
  getAcronymCode,
} from '../../../utils/contact-formatters';
import renderHTML from 'html-react-parser';
import Avatar from 'react-avatar';
import { getAllAssignProjectToPeopleAction } from '../../../actions/assignProjectToPeople';
import { getAllProjectRoleAction } from '../../../actions/projectRole';
import { selectGetProjectRoles } from '../../../modules/settings/selectors';
import { useAppSelector } from '../../../shared/hooks/use-app-selector';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import { getProjectRoles } from '../../../modules/settings';

function LeadHeader({ projectData, assignedProjectToPeopleList = [], isHideFields = false }) {
  const [projectId, setProjectId] = useState('');
  const [accountExecRole, setAccountExecRole] = useState({});
  const [clientRole, setClientRole] = useState({});

  const { data: allProjectRoleTypes } = useAppSelector(selectGetProjectRoles);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (allProjectRoleTypes?.length > 0) {
      const accountExecRole = allProjectRoleTypes.find((role) => role.code === 'AX');
      setAccountExecRole(accountExecRole);

      const clientRole = allProjectRoleTypes.find((role) => role.code === 'CL');
      setClientRole(clientRole);
    } else {
      dispatch(getProjectRoles());
    }
  }, [allProjectRoleTypes]);

  useEffect(() => {
    if (projectId) {
      getAllAssignProjectToPeopleAction(projectId);
    }
  }, [projectId]);

  const [clients, setClients] = useState([]);
    const [accountExecs, setAccountExecs] = useState([]);

  useEffect(() => {
    if(allProjectRoleTypes?.length > 0 && assignedProjectToPeopleList?.length > 0) {
        if(accountExecRole?._id)
        {
            setAccountExecs(assignedProjectToPeopleList.filter(item => item.projectRoleId === accountExecRole._id));
        }
        if(clientRole?._id)
        {
            setClients(assignedProjectToPeopleList.filter(item => item.projectRoleId === clientRole._id));
        }
    }
}, [allProjectRoleTypes, assignedProjectToPeopleList,accountExecRole,clientRole]);


  useEffect(() => {
    if (projectData?.objectId) {
      setProjectId(projectData.objectId);
    } else if (projectData?.projectId?.objectId) {
      setProjectId(projectData.projectId.objectId);
    }
  }, [projectData?.objectId]);

  let accountExec = '';
  
  let client = '';

  const accountExecRoleName = accountExecRole?.roleName ?? 'Account Executive';
  const clientRoleName = clientRole?.roleName ?? 'Client';

  if (accountExecs?.length > 0) {
    accountExecs.map((item) => {
        accountExec = accountExec + formatInternalTeamContact(item);
      }
    );
  }

  if (clients?.length > 0) {
    clients.map((item) => {
        client = client + formatExternalTeamContact(item);
      }
    );
  }

  const accountExecEnabled =
    accountExecs?.length > 0 || (accountExecRole?.isShowOnLeads && !accountExecRole.trash);

  const clientEnabled = clients?.length > 0 || (clientRole?.isShowOnLeads && !clientRole.trash);

  const userInfo_AX = accountExecs?.length > 0 && accountExecs[0];

  return (
    <div className='pDetail leadDetails'>
      <div className='row'>
        <div className='col'>
          <div className='row'>
            <div className='col-lg-3 col-md-4 col-sm-5 col-xs-6 mb-1'>Lead cohort:</div>
            <div className='col-lg-9 col-md-8 col-sm-7 col-xs-6 mb-1'>
              {projectData?.leadCohort || '-'}
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-3 col-md-4 col-sm-5 col-xs-6 mb-1'>Lead date:</div>
            <div className='col-lg-9 col-md-8 col-sm-7 col-xs-6 mb-1'>
              {projectData?.leadDate?.iso ? formatDate(projectData.leadDate.iso) : '-'}
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-3 col-md-4 col-sm-5 col-xs-6 mb-1'>Lead source:</div>
            <div className='col-lg-9 col-md-8 col-sm-7 col-xs-6 mb-1'>
              {projectData?.leadSource || '-'}
            </div>
          </div>
          {clientEnabled && (
            <div className='row'>
              <div className='col-lg-3 col-md-4 col-sm-5 col-xs-6 mb-1'>{clientRoleName}:</div>
              <div className='col-lg-9 col-md-8 col-sm-7 col-xs-6 mb-1'>
                {client ? renderHTML(client) : '-'}
              </div>
            </div>
          )}

          {accountExecEnabled && (
            <div className='row'>
              <div className='col-lg-3 col-md-4 col-sm-5 col-xs-6 mb-1'>{accountExecRoleName}:</div>
              <div className='col-lg-9 col-md-8 col-sm-7 col-xs-6 mb-1'>
                {accountExec ? renderHTML(accountExec) : '-'}
              </div>
            </div>
          )}
        </div>
      </div>
      {accountExec && userInfo_AX?.profileImage && (
        <div className='ms-auto'>
          <Avatar alt={`${userInfo_AX.userFullName}`} src={userInfo_AX?.profileImage} round={true}
                          size='45'>
            {`${getAcronymCode(userInfo_AX.userFullName)}`}
          </Avatar>
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    projectData: state.project.projectData,
    isProjectUpdated: state.project.isProjectUpdated,
    assignedProjectToPeopleList: state.assignProjectToPeople.assignedProjectToPeopleList,
  };
}

export default withRouter(
  connect(mapStateToProps, { getAllAssignProjectToPeopleAction })(LeadHeader)
);
