import { ReactNode, useEffect, useMemo, useState } from 'react';
import { getEmployees, getTeamActivity } from '../../modules/contacts';
import { selectGetTeamActivity } from '../../modules/contacts/selectors';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { useAppSelector } from '../../shared/hooks/use-app-selector';
import { CustomTable } from '../../components/Common/CustomTable';
//import { useTeamActivityPage } from './column-helper';

import TitleComponent from '../../components/Common/TitleComponent';
import { userInteraction, isAllowed } from '../../_constants';
import { Suspense } from 'react';

import Header from '../../components/Layout/Header.js';
import Footer from '../../components/Layout/Footer.js';

import usePagination from '../../shared/data-grid/use-pagination';
import RemotePagination from '../../shared/data-grid/remote-pagination';
import { SelectColumnFilter } from '../../components/Common/CustomTableComponents/SelectColumnFilter';
import moment from 'moment';
import { teamActivityTimeFormat } from '../../utils/date-formatters';
import { Link } from 'react-router-dom';
import avatarImage from '../../components/static/images/avatar3.png';

export interface FilterProps {
  column: {
    filterValue: any;
    setFilter: (value: any) => void;
    preFilteredRows: any[];
    id: string;
  };
}

export interface ColumnDefinition {
  Header: string;
  accessor: string | ((row: any) => any);
  Filter?: (props: FilterProps) => ReactNode;
  filter?: string | ((rows: any[], id: string[], filterValue: any) => any[]);
  canFilter?: boolean;
  canSort?: boolean;
}

export interface RowDefinition {
  [key: string]: any;
}


export const TeamActivity = () => {
  const dispatch = useAppDispatch();

  const [teamActivityData, setTeamActivityData] = useState([]);
  const [total, setTotal] = useState(0);

  const [users, setUsers] = useState([]);
  const [activityTypes, setActivityTypes] = useState([]);
  const [actionTypes, setActionTypes] = useState([]);
  const [filters, setFilters] = useState({});
  const [sortField, setSortField] = useState('_created_at');
  const [sortOrder, setSortOrder] = useState('desc');

  const pagination = usePagination({ total });

  const { page, limit } = pagination;
  const { loading } = useAppSelector(selectGetTeamActivity);
  //const [columns] = useTeamActivityPage();

  useEffect(() => {
    fetchTeamActivity();
  }, [page, limit, filters, sortField, sortOrder]);

  useEffect(() => {
    fetchUsers();
    fetchActionTypes();
    fetchActivityTypes();
  }, []);

  const fetchTeamActivity = () => {
    dispatch(getTeamActivity({
      count: limit,
      page,
      filters,
      sortBy: {
        field: sortField,
        order: sortOrder
      }
    }))
      .unwrap()
      .then((data) => {
        setTeamActivityData(data.teamActivity);
        setTotal(data.total);
      });
  };

  const fetchActionTypes = () => {
    setActionTypes([
      'create',
      'edit',
      'disable',
      'enable',
     'delete',
    ] as any);};

  const fetchUsers = () => {
    dispatch(getEmployees( { pagination: {offset:0,limit: 1000}, active: true, isUser:true } ))
      .unwrap()
      .then((data) => {
        setUsers(data.employees??[]);
      });
    /*

    dispatch(getUsers())
      .unwrap()
      .then((data) => {
        setUsers(data);
      });*/
  };

  const fetchActivityTypes = () => {
    setActivityTypes([
      'Phone Call',
      'Email',
      'Meeting',
      'Lunch',
      'Dinner',
      'Breakfast',
      'Coffee',
      'Other',
    ] as any);
    /*
    dispatch(getActivityTypes())
      .unwrap()
      .then((data) => {
        setActivityTypes(data);
      });*/
  };

  const handleFilterChange = (newFilters) => {
      newFilters = newFilters.reduce((acc, current) => {
        acc[current.id] = current.value;
        return acc;
      }, {});
    
    // check if newFilters is same as filters
    if (JSON.stringify(newFilters) === JSON.stringify(filters)) {
      return;
    }
    setFilters(newFilters);

  };

  const handleSortChange = (sortBy) => {

    sortBy = sortBy.filter(sort=>sort?.id);

    if(sortBy.length === 0) {
      return;
    }

    const sort = sortBy[0];
    setSortField(sort.id);
    setSortOrder(sort.desc ? 'desc' : 'asc');
    
    
  };

  const userOptions = useMemo(() =>
    (users as any).map(user => ({ label: `${user.firstName} ${user.lastName}`, value: user.contactId })),
    [users]
  );

  const actionTypeOptions = useMemo(() =>
    actionTypes.map(type => ({ label: type, value: type })),
    [actionTypes]
  );

  const activityTypeOptions = useMemo(() =>
    activityTypes.map(type => ({ label: type, value: type })),
    [activityTypes]
  );

  useEffect(() => {
    dispatch(getTeamActivity({ count: limit, page, filters, sortBy: { field: sortField, order: sortOrder } }))
      .unwrap()
      .then((data) => {
        setTeamActivityData(data.teamActivity);
        setTotal(data.total);
      });
  }, [page, limit]);

  const onGetUser = (data) => {
    if (data) {
      const { user = {} } = data;

      return (
        <Link to={{ pathname: `/contact-details/` + user.contactId }}>
          <img
            style={{ width: 25, display: 'block-inline', margin: '0 auto' }}
            className='dashAvatar rounded-circle'
            src={user && user.userImage ? user.userImage : avatarImage}
            alt='Avatar'
          />
          &nbsp;{[user.firstName, user.lastName].filter(Boolean).join(' ')}
        </Link>
      );
    }

    return null;
  };

  const onGetActivity = (data, accessor) => {
    if (data) {
      const { recordType, recordId, subRecordId, subRecordType } = data;

      let pathname = '';

      const subRecordTypeMap = {
        bid: 'bids',
        changeOrder: 'change-orders',
        email: 'email',
        note: 'notes',
        meeting: 'schedule',
      };

      const subRecordTypePath = subRecordTypeMap[subRecordType] ?? null;


      if (recordType === 'project') {
        pathname = `/project-details/${recordId}`;
      } else if (recordType === 'contact') {
        pathname = `/contact-details/${recordId}`;
      } else if (recordType === 'company') {
        pathname = `/company-details/${recordId}`;
      }

      if (subRecordTypePath && subRecordId?.length > 0 && accessor !== 'recordName') {
        pathname = pathname + `/${subRecordTypePath}/view/${subRecordId}`;
      }

      if (pathname) {
        return <Link to={{ pathname }}>{data[accessor]}</Link>;
      }

      return data[accessor];
    }

    return null;
  };

  const onGetMessage = (data, value) => {
    return value.replace('SYSTEM_DATE', teamActivityTimeFormat(data._created_at));
  };
  const onGetTime = (data) => moment(data._created_at).format('MM/DD/YYYY hh:mm a');

  const columns: ColumnDefinition[] = useMemo(() => [
    {
      Header: 'User',
      accessor: (row) => row.user.contactId,
      canSort: false,
      Filter: (props) => <SelectColumnFilter {...props} options={userOptions} />,
      filter: 'equals',
      Cell: ({ row }: RowDefinition) => onGetUser(row.original),
    },
    {
      Header: 'Activity Type',
      accessor: 'actionType',
      canSort: false,
      Filter:  (props) => <SelectColumnFilter {...props} options={actionTypeOptions} />,
      filter: 'equals',
      Cell: ({ row }: RowDefinition) => onGetActivity(row.original, 'actionType'),
    },
    {
      Header: 'Activity',
      accessor: 'action',
      canSort: false,
      Filter:  (props) => <SelectColumnFilter {...props} options={activityTypeOptions} />,
      filter: 'equals',
      Cell: ({ row }: RowDefinition) => onGetActivity(row.original, 'action'),
    },
    {
      Header: 'Parent Name',
      accessor: 'recordName',
      Cell: ({ row }: RowDefinition) => onGetActivity(row.original, 'recordName'),
    },
    {
      Header: 'Sub Item Name',
      accessor: 'subRecordName',
      Cell: ({ row }: RowDefinition) => onGetActivity(row.original, 'subRecordName'),
    },
    {
      Header: 'Message',
      accessor: 'description',
      canSort: false,
      Cell: ({ row }: RowDefinition) => onGetMessage(row.original, row.original.description),
    },
    {
      Header: 'Time',
      accessor: '_created_at',
      Cell: ({ row }: RowDefinition) => onGetTime(row.original),
      /* Filter: ({ column }) => (
         <RangePicker
           style={{ width: '100%' }}
           onChange={(dates) => column.setFilter(dates)}
         />
       ),
       filter: (rows, id, filterValue) => {
         // Implement date range filtering logic here
         return rows.filter(row => {
           const rowDate = new Date(row.values[id]);
           return (!filterValue[0] || rowDate >= filterValue[0]) &&
                  (!filterValue[1] || rowDate <= filterValue[1]);
         });
       },*/
    },
  ], [userOptions, activityTypeOptions, actionTypes]);

  return (
    <div className='ContactListReport'>
      <Suspense fallback='loading'>
        <TitleComponent data={{ title: `Team Activity` }} />
        <Header />
        <div className='row contactBox ms-2 me-2 row'>
          {isAllowed(userInteraction.TA, 'anyAssigned') && (
            <div className='col'>
              <div className='container'>
                <CustomTable
                customTableTitle='Team Activity'
                  columns={columns}
                  list={teamActivityData}
                  inlineEdit={false}
                  canShowExport={false}
                  canShowPagination={false}
                  canShowGlobalFilter={false}
                  canShowColumnSettings={false}
                  canShowDateFilter={false}
                  canShowRowSelection={false}
                  canSort={true}
                  canFilter={true}
                  onSortChange={handleSortChange}
                  onFilterChange={handleFilterChange}
                  manualSortBy={true}
                  manualFilters={true}
                  isLoading={loading}
                  initialSortBy={[{ id: sortField, desc: sortOrder === 'desc' }]}
                  initialFilters={filters}
                />
                <RemotePagination {...pagination} />
              </div>
            </div>
          )}
        </div>
        <Footer />
      </Suspense>
    </div>
  );
};

export default TeamActivity;
