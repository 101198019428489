import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePickerWrapper from '../../shared/datepicker-wrapper/datepicker-wrapper';
import { formatDateObjectOrNull, formatDateMMDDYY } from '../../utils/date-formatters';
import { appConstants } from '../../_constants';
import moment from 'moment';
import { renderHeader } from '../../components/Common/UpdateProjectStatusModal/update-project-status-modal';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { updateProjectStatusAction } from '../../actions/projectStatus';
import { selectGeneralInfo } from '../../selectors/accountSettings';
import { useSelector } from 'react-redux';

const UpdateProjectDatesModal = ({ isOpen, toggle, projectData, onUpdate }) => {

  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [selectDateBox, setSelectDateBox] = useState({
    startDate: projectData.startDate?.iso,
    endDate: projectData.endDate?.iso,
  });
  const [statusUpdateProject, setStatusUpdateProject] = useState({
    ...projectData.updateStatus,
    projectId: projectData.objectId,
    startDate: projectData.startDate?.iso,
    endDate: projectData.endDate?.iso,
    nextStatus: projectData.jobStatusCodes,
    comment: '',
    updateType: 'UpdateWIP'
  });

  useEffect(()=>
  {
    if(projectData?.projectId !== statusUpdateProject.projectId){
      setStatusUpdateProject(({
        ...projectData.updateStatus,
        projectId: projectData.objectId,
        startDate: projectData.startDate?.iso,
        endDate: projectData.endDate?.iso,
        nextStatus: projectData.jobStatusCodes,
        comment: '',
        updateType: 'UpdateWIP'

      
      }));
    }
  },[projectData?.projectId]
);



  const dispatch = useAppDispatch();

  const handleDatePickerChange = (name, date) => {
    setSelectDateBox(prev => ({ ...prev, [name]: date }));
    setStatusUpdateProject(prev => ({ ...prev, [name]: date }));
  };

  const handleCommentChange = (e) => {
    setStatusUpdateProject(prev => ({ ...prev, comment: e.target.value }));
  };

  const calculateDuration = (start, end) => {
    if(!start || !end){
      return '-';
    }
    return moment(end).diff(moment(start), 'days') + 1;
  };

  const calculateVariance = (original, current) => {
    if(!original || !current){
      return '-';
    }
    return moment(current).diff(moment(original), 'days');
  };

  const onSubmit = async (updateData) => {
    try {
      setIsLoading(true);
      await dispatch(
        updateProjectStatusAction(updateData)
      );
      onUpdate(updateData);
      toggle();
    } catch (err) {
      setErrorMsg('Failed to update status');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = () => {

    onSubmit(statusUpdateProject);
  };

  const renderDateIfExists = (date_object) => {
    if(!date_object)
      return '-';

    return formatDateMMDDYY(formatDateObjectOrNull(date_object));
  }

  const accountInfo = useSelector(selectGeneralInfo);

  const restrictContractAdjustments = accountInfo?.restrictContractAdjustments??true;

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-dialog modal-lg">
      <ModalHeader toggle={toggle}>Update actual / projected dates</ModalHeader>
      <ModalBody>
        <div className="container">
          {renderHeader(projectData)}

          <table className="table">
            <thead>
              <tr>
                <th>Dates</th>
                <th>Original Contract</th>
                <th>Current Contract</th>
                <th>Actual / projected*</th>
                <th>Variance (days)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Start date</td>
                <td>{renderDateIfExists(projectData.baselineStartDate)}</td>
                <td>{renderDateIfExists(projectData.baselineStartDate)}</td>
                <td>
                  <DatePickerWrapper
                    selected={formatDateObjectOrNull(selectDateBox.startDate)}
                    onChange={(date) => handleDatePickerChange('startDate', date)}
                    dateFormat={appConstants.DATEFORMATPICKER}
                    placeholderText="Click to select a date"
                    todayButton="Today"
                    showMonthDropdown
                  />
                </td>
                <td className="text-danger">
                  {calculateVariance(projectData.startDate?.iso, selectDateBox.startDate)}
                </td>
              </tr>
              <tr>
                <td>End date</td>
                <td>{renderDateIfExists(projectData.baselineEndDate)}</td>
                {restrictContractAdjustments?
                <td>{renderDateIfExists(projectData.currentBaselineEndDate)}</td>: <td>
                <DatePickerWrapper
                  selected={formatDateObjectOrNull(selectDateBox.endDate)}
                  onChange={(date) => handleDatePickerChange('endDate', date)}
                  dateFormat={appConstants.DATEFORMATPICKER}
                  placeholderText="Click to select a date"
                  todayButton="Today"
                  showMonthDropdown
                />
              </td>}
                <td>
                  <DatePickerWrapper
                    selected={formatDateObjectOrNull(selectDateBox.endDate)}
                    onChange={(date) => handleDatePickerChange('endDate', date)}
                    dateFormat={appConstants.DATEFORMATPICKER}
                    placeholderText="Click to select a date"
                    todayButton="Today"
                    showMonthDropdown
                  />
                </td>
                <td className="text-danger">
                  {calculateVariance(projectData.endDate?.iso, selectDateBox.endDate)}
                </td>
              </tr>
              <tr>
                <td>Duration (days)</td>
                <td>{calculateDuration(projectData.baselineStartDate?.iso, projectData.baselineEndDate?.iso)}</td>
                <td>{calculateDuration(projectData.baselineStartDate?.iso, projectData.currentBaselineEndDate?.iso)}</td>
                <td>{calculateDuration(selectDateBox.startDate, selectDateBox.endDate)}</td>
                <td className="text-danger">
                  {calculateDuration(selectDateBox.startDate, selectDateBox.endDate) - 
                   calculateDuration(projectData.startDate?.iso, projectData.endDate?.iso)}
                </td>
              </tr>
            </tbody>
          </table>

          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label><strong>Comment:</strong></label>
                <textarea
                  className="form-control"
                  rows="2"
                  onChange={handleCommentChange}
                  name="comment"
                  value={statusUpdateProject.comment}
                  placeholder="Comment"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="modalFooter">
        <button className="btn btn-primary" onClick={toggle}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={handleUpdate} disabled={isLoading}>
          Update
        </button>
      </ModalFooter>
    </Modal>
  );
};

export {UpdateProjectDatesModal};