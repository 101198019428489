import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import { getUserSettings } from '../../../modules/settings';
import { updateProjectStatusAction } from '../../../actions/projectStatus';
import { getJobStatusCodesSettings } from '../../../modules/settings';
import styles from './update-project-status-modal.module.scss'

export const renderHeader = (projectData) => {
  const hasJobNumber = projectData?.jobNumFormatted?.length > 0;
  const hasAltJobNumber = projectData?.altJobNum?.length > 0;

  return (
    <div className='row'>
      <div className='col'>
        <div className={`form-group mb-3 ${styles.jobName}`}>
          <label>Job name:</label>
          <input
            type='text'
            className={`form-control ${styles.jobBlockInfo}`}
            value={projectData?.jobName ?? ''}
            disabled
          />
        </div>
      </div>
      {hasJobNumber && (
        <div className='col'>
          <div className={`form-group mb-3 ${styles.jobName}`}>
            <label>Job number:</label>
            <input
              type='text'
              className={`form-control ${styles.jobBlockInfo}`}
              value={projectData.jobNumFormatted}
              disabled
            />
          </div>
        </div>
      )}
      {hasAltJobNumber && (
        <div className='col'>
          <div className={`form-group mb-3 ${styles.jobName}`}>
            <label>Alt. Job number:</label>
            <input
              type='text'
              className={`form-control ${styles.jobBlockInfo}`}
              value={projectData.altJobNum}
              disabled
            />
          </div>
        </div>
      )}
    </div>
  );
};

const UpdateProjectStatusModal = ({
  isOpen,
  toggle,
  projectId,
  projectData,
  currentStatus,
  onAfterUpdate,
  actionType = null,
}) => {
  const dispatch = useDispatch();
  const { control, handleSubmit, setValue, watch } = useForm();

  const [loading, setLoading] = useState(true);
  const [errorMsg, setError] = useState(null);
  const [nextStatusOptions, setNextStatusOptions] = useState([]);

  const selectedAction = watch('action');
  const nextStatus = watch('nextStatus');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
        const [actionsResult, statusCodesResult] = await Promise.all([
          dispatch(getUserSettings(['projectActions'])),
          dispatch(getJobStatusCodesSettings()),
        ]);

        const actions = actionsResult.payload.filter(
          (action) => !action.trash && action.initialStatus.includes(currentStatus)
        );
        const statusCodes = statusCodesResult.payload
          .filter((code) => !code.trash && !code.isSystemMaster)
          .map((code) => ({
            _id: code._id,
            jobStatusCodesName: code.jobStatusCodesName,
            jobStatusCodesCode: code.jobStatusCodesCode,
            permissionsGroupName: code.permissionsGroupName,
          }));

        if (actionType) {
          const action = actions.find((a) => a.code === actionType);
          if (action) {
            setValue('action', { value: action.code, label: action.name, ...action });
            updateNextStatusOptions(action, statusCodes);
          } else {
            setError('No valid action found');
          }
        } else if (actions.length === 1) {
          const action = actions[0];
          setValue('action', { value: action.code, label: action.name, ...action });
          updateNextStatusOptions(action, statusCodes);
        } else {
          setNextStatusOptions([]);
        }

        setLoading(false);
      } catch (err) {
        setError('Failed to load data');
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchData();
    }
  }, [dispatch, currentStatus, actionType, isOpen]);

  const updateNextStatusOptions = (action, statusCodes) => {
    const validStatusOptions = action.nextStatus.map((status) =>
      statusCodes.find((code) => code.jobStatusCodesCode === status)
    );
    const nextStatusOptions = validStatusOptions
      .filter(Boolean)
      .map((code) => ({ value: code._id, label: code.jobStatusCodesName }));

    setNextStatusOptions(nextStatusOptions);
    if (nextStatusOptions.length === 1) {
      setValue('nextStatus', nextStatusOptions[0]);
    }
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await dispatch(
        updateProjectStatusAction({
          projectId: projectId,
          nextStatus: data.nextStatus.value,
          updateType: selectedAction.updateType,
          comment: data.comment,
        })
      );
      onAfterUpdate();
      toggle();
    } catch (err) {
      setError('Failed to update status');
    } finally {
      setLoading(false);
    }
  };

  const modalTitle = selectedAction
    ? selectedAction.title?.replace('$NEXTSTATUSNAME', nextStatus?.label || '')
    : 'Update project status';

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
      <ModalBody>
        {loading ? (
          <p>Loading...</p>
        ) : errorMsg ? (
          <p className='text-danger'>{errorMsg}</p>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            {renderHeader(projectData)}

            {!actionType && (
              <div className='form-group mb-3 row '>
                <label className={`col-sm-3 col-form-label ${styles.updateFieldLabel}`}>
                  Action:{' '}
                </label>
                <div className='col-sm-9'>
                  <Controller
                    name='action'
                    control={control}
                    rules={{ required: 'Action is required' }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={nextStatusOptions.map((status) => ({
                          value: status.value,
                          label: status.label,
                        }))}
                      />
                    )}
                  />
                </div>
              </div>
            )}

            {selectedAction?.allowComments && false && (
              <div className='form-group mb-3 row '>
                <label className={`col-sm-3 col-form-label ${styles.updateFieldLabel}`}>
                  Comment:{' '}
                </label>
                <div className='col-sm-9'>
                  <Controller
                    name='comment'
                    control={control}
                    render={({ field }) => (
                      <textarea {...field} className='form-control' rows={3} />
                    )}
                  />
                </div>
              </div>
            )}
          </form>
        )}
      </ModalBody>
      <ModalFooter>
        <button className='btn btn-secondary' onClick={toggle}>
          Cancel
        </button>
        <button
          className='btn btn-primary'
          onClick={handleSubmit(onSubmit)}
          disabled={loading || !nextStatus || errorMsg?.length > 0}
        >
          Update
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default UpdateProjectStatusModal;
