import React, { useRef, Suspense, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getAccountSettingsAction } from '../../actions/accountSettings';
import { getAllAssignProjectToPeopleAction } from '../../actions/assignProjectToPeople';
import { getExistingProjectAction } from '../../actions/project';
import { appConstants } from '../../_constants';
import TitleComponent from '../Common/TitleComponent';
import DetailsRightSection from '../Layout/DetailsRightSection';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import loaderImage from '../static/images/loading_i.gif';
import DatesAndDollars from './ProjectDetailComponent/DatesAndDollars.component';
import InternalProjectTeam from './ProjectDetailComponent/InternalProjectTeam';
import MiscellaneousInfo from './ProjectDetailComponent/MiscellaneousInfo';
import ProjectDetailToolbar from './ProjectDetailComponent/ProjectDetailToolbar';
import ProjectTeam from './ProjectDetailComponent/ProjectTeam';
import SiteInfo from './ProjectDetailComponent/SiteInfo';
import ProjectNotFound from './project-not-found';

const ViewProjectDetails = (props) => {
  const [accountId] = useState(localStorage.getItem('accountId'));
  const [projectId, setProjectId] = useState('');
  const [fetchRequest1, setFetchRequest1] = useState(true);
  const [fetchRequestSent, setFetchRequestSent] = useState(false);
  const [isHideFields, setIsHideFields] = useState(true);
  const [isProjectNotFound, setIsProjectNotFound] = useState(false);
  const projectToolbarRef = useRef(null);
  const componentToPrint = useRef(null);
  const [projectData, setProjectData] = useState(null);

  useEffect(() => {
    let objectId = props.match.params.objectId;
    if (objectId) {
      let projectData = {};
      setProjectId(objectId);
      projectData['projectId'] = objectId;
      props.getExistingProjectAction(projectData);
      list(objectId);
      props.getAccountSettingsAction();
    }
    window.scroll(0, 0);
  }, [props.key]);

  const [lastProjectLoaded, setLastProjectLoaded] = useState('');
  const [lastUpdated, setLastUpdated] = useState('');

  useEffect(() => {
    if (props.projectData?.isProjectNotFound ===true) {
      setIsProjectNotFound(true);
    }else{
      setIsProjectNotFound(false);
    }
    

    if (props.projectData?.objectId && (props.projectData.objectId !== lastProjectLoaded) && props.projectData?.updatedAt) {
      
      setLastProjectLoaded(props.projectData.objectId);
      setLastUpdated(props.projectData.updatedAt);
    } else if (props.projectData?.isProjectNotFound !== undefined && !props.projectData.isProjectNotFound || (!props.fetchRequest && !props.projectData)) {
      setIsProjectNotFound(false);
    } else {
      setFetchRequest1(true);
    }

    if (
      props.projectData?.objectId &&
      (props.projectData.jobStatusCodesCode === appConstants.jobStatusCode.L ||
        props.projectData.jobStatusCodesCode === appConstants.jobStatusCode.NP)
    ) {
      props.history.replace(
        props.location.pathname.replace('project-details', 'lead-details')
      );
    }

    if (props.projectData?.objectId && (!projectData || (projectData?.objectId) && (props.projectData?.objectId !== projectData?.objectId))) {
      setProjectData(props.projectData);
      setLastProjectLoaded(props.projectData.objectId);

      props.getAllAssignProjectToPeopleAction({ projectId: props.projectData.objectId });
    } else if (props.projectData?.updatedAt && props.projectData.updatedAt !== lastUpdated) {
      setProjectData(props.projectData);

      props.getAllAssignProjectToPeopleAction({ projectId: projectId });
    }

    if (props.isProjectUpdated !== props.isProjectUpdated && props.isProjectUpdated) {
      props.getExistingProjectAction({ projectId: projectId });
      setFetchRequestSent(true);
      props.getAllAssignProjectToPeopleAction({ projectId: projectId });
    }

    setFetchRequest1(props.fetchRequest);
  }, [props]);


  const list = (objectId) => {
    let data = {};
    data['accountId'] = accountId;

    props.getAllAssignProjectToPeopleAction({
      projectId: objectId,
      accountId: accountId,
    });
  };

  useEffect(() => {
    if(props?.isProjectNotFound ===true){
      setIsProjectNotFound(true);
    }else{
      setIsProjectNotFound(false);
    }
  }
  , [props?.isProjectNotFound]);
  


  const onOpenEditModal = (section) => {
    projectToolbarRef.current?.onEditProjectClick(section);
  };

  const onManageBidModal = () => {
    projectToolbarRef.current?.onManageBidModal();
  };

  const onManageContractModal = () => {
    projectToolbarRef.current?.onManageContractModal();
  };

  const onUpdateWIPModal = () => {
    projectToolbarRef.current?.onUpdateWIPModal();
  };

  if (isProjectNotFound) {
    return <ProjectNotFound />;
  }

  return (
    <Suspense fallback='loading'>
      <div className='ContactListReport'>
        <TitleComponent
          data={{
            title: `Project Details - ${projectData?.jobName ? projectData.jobName : ' NA'}`,
          }}
        />
        <Header props={props} />
        <div className='detailsPage'>
          {fetchRequest1 ? (
            <div className='loading_bg'>
              <img className='ajax-loader' src={loaderImage} width='100' height='100' />
            </div>
          ) : (
            ''
          )}
          <div ref={(el) => (componentToPrint.current = el)}>
            <div className='container-xxl'>
              <div className='row mb-0'>
                <ProjectDetailToolbar
                  projectId={projectId}
                  projectData={projectData}
                  componentToPrint={componentToPrint}
                  hideFieldsCallback={setIsHideFields}
                  ref={projectToolbarRef}
                />
              </div>
              <div className='row mt-1'>
                <div className='col-md d-print-block'>
                  <div className='projectDetailSidebar'>
                    {<SiteInfo
                      projectStage={'Active'}
                      projectData={projectData}
                      onOpenEditModal={onOpenEditModal}
                      isHideFields={isHideFields}
                    />}
                    <DatesAndDollars
                      projectData={projectData}
                      onManageBidModal={onManageBidModal}
                      onManageContractModal={onManageContractModal}
                      onUpdateWIPModal={onUpdateWIPModal}
                    />
                    <InternalProjectTeam
                      projectData={projectData}
                      onOpenEditModal={onOpenEditModal}
                      isHideFields={isHideFields}
                    />
                    <ProjectTeam
                      projectData={projectData}
                      isLead={false}
                      onOpenEditModal={onOpenEditModal}
                      isHideFields={isHideFields}
                    />
                    <MiscellaneousInfo projectData={projectData} isHideFields={isHideFields}/>
                  </div>
                </div>
                <div className='col-md col-lg-5 col-xl-6 mt-2'>
                  <div className='projectTabsBox'>
                    {projectData && projectData.objectId && (
                      <DetailsRightSection
                        typeRef={'PROJECT'}
                        typeId={projectData.objectId}
                        data={projectData}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Suspense>
  );
};

function mapStateToProps(state) {
  return {
    fetchRequest: state.project.fetchRequest,
    projectData: state.project.projectData,
    isProjectUpdated: state.project.isProjectUpdated,
    isProjectNotFound: state.project.projectData?.isProjectNotFound,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getExistingProjectAction,
    getAccountSettingsAction,
    getAllAssignProjectToPeopleAction,
  })(ViewProjectDetails)
);
