import renderHTML from 'html-react-parser';
import { Link } from 'react-router-dom';
import {Tooltip} from 'react-tooltip';
import { formatPhoneForCall, formatPhone } from './formatters';

function getTrimString(val) {
  const maxTrimLength = 8;
  let str = val;
  const res = str ? str.substring(0, maxTrimLength) : '';
  return res;
}
export const getAcronymCode = (name) => {
  if (!name) return '';

  return name
    .replace(/[^a-zA-Z0-9 ]/g, '') // Remove non-alphanumeric characters
    .split(' ') // Split into words
    .reduce((acronym, word, index) => {
      // Skip 'THE' if it's the first word
      if (index === 0 && word.toUpperCase() === 'THE') return acronym;
      
      return acronym + word.charAt(0).toUpperCase();
    }, '');
};

export const formatPartialContactDataHTMLDropdown = (contactTo) => {
  
  let contactString = contactTo.label??'';
 
  if (contactTo.value?.length>4) {
    contactString +=
      '<br/> <small>(' +
      contactTo.value +
      ')</small>';
  }
  return contactString;
}

export const formatProjectOption = (affiliation = {}) => {
  const { companyName, contactFullName, primaryEmail = {}, roleName } = affiliation;
  const { email, label } = primaryEmail;

  const nameStr = [companyName, contactFullName].filter(Boolean).join(' - ');
  const emailStr = `<small>(${email})${label ? ` - ${label}` : ''}</small>`;
  const roleStr = roleName ? `<small>(${roleName})</small>` : '';

  return [nameStr, emailStr, roleStr].join('<br/>');
};

export const formatCompanyOption = (affiliation = {}) => {
  const { companyName, companyEmail } = affiliation;

  const emailStr = `<small>(${companyEmail})</small>`;

  return [companyName, emailStr].join('<br/>');
};

export const formatCompanyContactOption = (contact = {}) => {
  const { title, email, firstName, lastName, middleName } = contact;

  const contactName = [firstName, middleName, lastName].filter(Boolean).join(' ');
  const emailStr = `<small>(${email})</small>`;
  const titleStr = title ? `<small>(${title})</small>` : '';

  return [contactName, emailStr, titleStr].join('<br/>');
};

export const formatContactOption = (contact = {}) => {
  const { contactTitle, email, firstName, lastName, middleName } = contact;

  const contactName = [firstName, middleName, lastName].filter(Boolean).join(' ');
  const emailStr = `<small>(${email})</small>`;
  const titleStr = contactTitle ? `<small>(${contactTitle})</small>` : '';

  return [contactName, emailStr, titleStr].join('<br/>');
};

export const formatContactDataHTMLDropdown = (contact) => {
  let companyName =
    contact?.companyName?.length>0? getTrimString(contact.companyName) :'';
  let contactName = [contact.firstName,contact.middleName,contact.lastName].filter(Boolean).join(' ');
  let contactString =
    (companyName ? companyName + ' - ' : '') + contactName;

  let emailString=contact.email? contact.email : '';

  let phoneArr= contact?.phoneArr;
  let joinedPhoneArr = '';

  for (let ci in phoneArr){
    if(phoneArr[ci]?.phone){
      if(joinedPhoneArr.length==0){
        joinedPhoneArr = phoneArr[ci].phone;
      }else{
        joinedPhoneArr+=', '+phoneArr[ci].phone;
        }
    }
  }

  if (emailString && emailString.length>4) {
    contactString +=
      '<br/> <small>(' +
      contact.email +
      (joinedPhoneArr?', '+joinedPhoneArr:'') +
      ')</small>';
  } else {
    if (joinedPhoneArr && joinedPhoneArr?.length>0) {
      contactString += '<br/><small> (' + joinedPhoneArr + ')</small>';
    }
  }
  return contactString;
}

export const formatAddressText = (addressObj) => {
 
  let addressText = [addressObj?.address1??addressObj?.jobAddress??addressObj?.companyAddress1,
    addressObj?.address2??addressObj?.jobAddress2??addressObj?.companyAddress2,
    addressObj?.city??addressObj?.jobCity,
    addressObj?.state??addressObj?.jobState,addressObj?.zipCode??addressObj?.jobZip,
    addressObj?.country].filter(Boolean).join(', ')??'';

  return addressText;
}

export const formatAddressHTML = (v) => {
 
  if (v.address1 || v.address2 || v.city || v.state || v.zipCode) {
    return <div>{v.label?.length>0&&(<><span><strong> {v.label}</strong></span><br></br></>)}
      <span>{
      (v.address1 ? v.address1 : '') +
      (v.address1 ? ', ' : '') +
      (v.address2 ? v.address2 : '') +
      (v.address2 ? ', ' : '')}
      </span>
      <br/>
      <span>
    {(v.city ? v.city : '') +
      (v.city ? ', ' : '') +
      (v.state ? v.state : '') +
      (v.state ? ', ' : '') +
      (v.zipCode ? v.zipCode : '')}
      </span><br/></div>;
  }else{
    return <span>-</span>
  }
}







export const getExternalContactDropdownLabel = (contact) => {
  let emailStatus = contact && contact.email?.length>4 ? contact.email : '';
  let contactName = [contact.firstName,contact.middleName,contact.lastName].filter(Boolean).join(' ');
  let venderArr =
    contact && contact.venderArr && contact.venderArr[0]
      ? getTrimString(contact.venderArr[0].label)
      : '';
  let contactString =
    (venderArr ? venderArr + ' - ' : '') + contactName;

  if (emailStatus) {
    contactString +=
      '<br/><small> (' +
      contact.email +
      (contact.phoneNumber ? ', ' + contact.phoneNumber : '') +
      ')</small>';
  } else {
    if (contact.phoneNumber) {
      contactString += '<br/><small> (' + contact.phoneNumber + ')</small>';
    }
  }
  return renderHTML(contactString);
};

export const formatUserDataHTMLDropdown = (companyName, contact) => {
  let contactName = [contact.firstName,contact.middleName,contact.lastName].filter(Boolean).join(' ');

  let userString =
    (companyName ? getAcronymCode(companyName) + ' - ' : '') +
    contactName;

  let phoneArr= contact?.phoneArr;
  let joinedPhoneArr = '';

  for (let ci in phoneArr){
    if(phoneArr[ci]?.phone){
      if(joinedPhoneArr.length==0){
        joinedPhoneArr = phoneArr[ci].phone;
      }else{
        joinedPhoneArr+=', '+phoneArr[ci].phone;
        }
    }
  }

  let emailString=contact.email? contact.email : '';
  if (emailString && emailString.length>4) {
    userString +=
      '<br/><small> (' +
      contact.email +
      (joinedPhoneArr?', '+joinedPhoneArr:'') +
      ')</small>';
  } else {
    if (joinedPhoneArr && joinedPhoneArr.length>0) {
      userString += '<br/><small> (' + joinedPhoneArr + ')</small>';
    }
  }
  return userString;
};

export const getInternalContactDropdownLabel = (contact) => {
  const company = localStorage.getItem('companyName');
  let emailStatus = contact && contact.email?.length>4 ? contact.email : '';
  let contactName = [contact.firstName,contact.middleName,contact.lastName].filter(Boolean).join(' ');
  let userString =
    (company ? getTrimString(company) + ' - ' : '') + contactName;
  if (emailStatus) {
    userString +=
      '<br/><small> (' +
      contact.email +
      (contact.phoneNumber ? ', ' + contact.phoneNumber : '') +
      ')</small>';
  } else {
    if (contact.phoneNumber) {
      userString += '<br/><small> (' + contact.phoneNumber + ')</small>';
    }
  }
  return renderHTML(userString);
};

export const formatExternalContactData = (row, contactData, showPhone=true, showCompany = true) => {
  if (row && row.length > 0) {
    return (
      <div>
        {row.map((item, i) => {
          const id = item?.id??item?.contactAffiliationId??item?.userId??item?.objectId??item;
          let data = contactData[id];

          if(contactData[0]&&!data){
            data=contactData.find(contact=>contact.objectId==id);
          }
          
          if (data) {
            let contactName = [data.firstName,data.middleName,data.lastName].filter(Boolean).join(' ');
            let contactFullName = data.displayName ? data.displayName : contactName ?? '-';
            let companyName = !showCompany?'':data.dba?.length>0?data.dba:data.companyName?data.companyName: data.venderArr && data.venderArr.length>0 && data.venderArr[0].label ?data.venderArr[0].label  : '';
              let comma;
            if (i != 0) {
              comma = ', ';
            } else {
              comma = '';
            }
            
            const isCompany = data.isCompany;
            const contactId = data.contactId??data.objectId;
            const companyId = data.companyId;

            const email = data.primaryEmail?.email?data.primaryEmail.email:data.email;
            const phone = phoneArrTelText(data.phoneArr??data.primaryPhone??data.phone);
            const title = data.title??data.contactTitle;

            let hoverString = ('<b>'+contactFullName+'</b>')+(!isCompany&&companyName?'<br/>'+companyName:'')+(title?'<br/>'+title+'':'')
              +(email?.length>0?('<br/>'+(email)):'')
              +(phone?.length>0?('<br/>'+phone):'');

            const nameLine = [companyName,!isCompany&&companyName.length>0?'-':'',!isCompany&&contactFullName].filter(Boolean).join(' ');
              
            return (
              <span key={'iContact'+id+'-'+i}>
                {comma}
                <Link data-tooltip-html={hoverString}
                               data-tooltip-id={row.id+'h'+i}
                               to={{ pathname: isCompany?(`/company-details/` + companyId):(`/contact-details/` + contactId) }}>
                  {nameLine}
                </Link>
                <Tooltip id={row.id+'h'+i}  delayShow={1000} place="bottom" />
                <br></br>
                {showPhone?phoneArrTelHtml(data.phoneArr??data.phone):''}
              </span>
            );
          }
        })}
      </div>
    );
  } else {
    return <span>-</span>;
  }
}

export const formatInternalContactData = (row, contactData) => {
  if (row && row.length > 0) {
    return (
      <div>
        {row.map((item, i) => {
          const id = item?.contactAffiliationId??item?.userId??item?.id??item?.objectId??item;
          const data = contactData[id]??(contactData.length>0&&contactData.find(contact=>contact.objectId==id));
          if (data) {
            
            
            let contactName = [data.firstName,data.middleName,data.lastName].filter(Boolean).join(' ');
            let contactFullName = data.displayName ??
              contactName ?? '-';
            let contactInitials = getAcronymCode(contactFullName).trim();
            if(contactInitials.length==0){
              contactInitials="??";
            }
            let comma;
            if (i != 0) {
              comma = ', ';
            } else {
              comma = '';
            }
            const email = data.primaryEmail?.email??data.email;
            const phone = phoneArrTelText(data.phoneArr?.phone??data.phone);
            let hoverString = ('<b>'+contactFullName+'</b>')+(data.title?'<br/>'+data.title+'':'')
            +(email?.length>0?('<br/>'+(email)):'')
            +(phone?.length>0?('<br/>'+phone):'');
            //some unique id
            return (<span key={'iContact'+id+'-'+i}>
              
              {comma}<Link data-tooltip-html={hoverString}
                               data-tooltip-id={row.id+'h'+i } to={{ pathname: `/contact-details/` + (data.contactId??data.objectId) }}>
                
                {contactInitials}
              </Link>
              <Tooltip id={row.id+'h'+i}  delayShow={1000} place="bottom" /></span>
            );
          }else{
            return('Deleted');
          }
        })}
      </div>
    );
  } else {
    return <span>-</span>;
  }
}

export const formatInternalTeamContact=(contactsData)=>
{
  const email = contactsData?.email?.length>4 && contactsData?.email || '';
  const venderArr = getAcronymCode(localStorage.getItem('companyName'));
  const ur = `/contact-details/${contactsData.objectId ?? contactsData.contactId}`;

  
  const fullName = contactsData.userFullName??[contactsData.firstName,contactsData.middleName,contactsData.lastName].filter(Boolean).join(' ');

 return  ('<a href=' +
  ur +
  '>' +
  (venderArr ? venderArr + ' - ' : '') +
  fullName+
  '</a>') +
(contactsData.phoneNumber || email ? ' (' : '') +
(email
  ? '<small><a href="mailto:' +
    contactsData.email +
    '">' +
    contactsData.email +
    '</a>'
  : '') +
(contactsData.phoneNumber
  ? ', ' +
    telForPhoneNumber(
      contactsData.phoneNumber.ext,
      contactsData.phoneNumber.phone
    )
  : '') +
'</small>' +
(contactsData.phoneNumber || email ? ')' : '');
}

export const formatExternalTeamContact=(contactData)=>
{
  const email = contactData?.email?.length>4 && contactData?.email || '';
  let primaryVenderName = '';
  if (contactData && contactData.venderArr && contactData.venderArr.length > 0) {
    primaryVenderName =
    contactData && contactData.venderArr && contactData.venderArr[0]
        ? contactData.venderArr[0].label
        : '';
  }

  let venderURL = '';
  if(primaryVenderName){
    venderURL = `<span title="`+primaryVenderName+`"><a href="/company-details/${contactData.venderArr[0].venderId}">`+getTrimString(primaryVenderName)+'</a></span> - ';
  } 
  
  const ur = `"/contact-details/${contactData.contactId??contactData.userId}"`;

  let phoneArrHtml = [];
  if (contactData && contactData.phoneArr && contactData.phoneArr.length > 0) {
    contactData.phoneArr.map((phoneItem) => {
      if(phoneItem.phone){
        phoneArrHtml.push(telForPhoneNumber(phoneItem.ext,phoneItem.phone));
      }
    });
  }

 return  (venderURL+'<a href=' +
  ur +
  '>' +
  contactData.userFullName +
  '</a>') +
(phoneArrHtml.length>0 || email ? ' (' : '') +
(email
  ? '<small><a href="mailto:' +
  email +
    '">' +
    email +
    '</a>'
  : '') +
((email&&phoneArrHtml.length>0 ?',':'')+phoneArrHtml.join(',')) +
'</small>' +
(phoneArrHtml.length>0 || email ? ')' : '');
}

export const internalExcelContact = (val, contactData) => {

  if(val.length>0&&typeof(val)==='object'){
    return val.map(contact=> contactData[contact.contactAffiliationId]?.displayNameWithCompany??'Deleted').join(', ');
  }else if(val.contactAffiliationId){
    if(contactData[val.contactAffiliationId]){
      return contactData[val.contactAffiliationId].displayNameWithCompany??'Deleted';
    }else{
      return '-';
    }
  }
  else if(val.length>0 && typeof(val)==='string'){
    if(contactData[val]){
      return contactData[val].displayNameWithCompany??'Deleted';
    }else{
      return '-';
    }
  }
  else{
    return '-';
  }
  
}

export const externalExcelContact = (val, contactData) => {
  let accountExec = '';
  if (val && val.length > 0) {
    val.map((item, i) => {
      let id = item.userId;
      let data = contactData[id];
      if (data) {
        const contactName = [data.firstName,data.middleName,data.lastName].filter(Boolean).join(' ');
        let comma;
        if (i != 0) {
          comma = ', ';
        } else {
          comma = '';
        }

        let phn = ', ';
        if (data.phoneArr && data.phoneArr.length > 0) {
          let phnB = '';
          data.phoneArr.map((item, i) => {
            if (parseInt(i) + 1 == data.phoneArr.length) {
              phn = '';
            }
            if (item.phone !== 'undefined' && item.phone !== '') {
              if (item.ext) {
                phnB += item.type + ': ' + '(' + item.ext + ') ' + item.phone + phn;
              } else {
                phnB += item.type + ': ' + item.phone + phn;
              }
              phn = phnB;
            }
          });
        }
        accountExec += comma + contactName + phn;
      }
    });
    return accountExec;
  } else {
    return <span>-</span>;
  }
};

function phoneArrTelText (phoneArr) {
  let formattedPhones = [];
  if (phoneArr && phoneArr.length > 0) {

    phoneArr.map((phone, index) => {
      let formattedPhone = formatPhone(phone,[],true);

      let phoneNumber = (
        '<span>'+formattedPhone+'</span>');
        formattedPhones.push(phoneNumber);
    });
  }
  return formattedPhones;
}

function telForPhoneNumber(type, number) {
  return '<a href="tel:' + (type?type:'') + number + '">' + formatPhone({'phone':number},[]) + '</a>';
}

export const getTelLinkForPhoneNumber = (type,number)=>{
  return telForPhoneNumber(type, number) ;
}


function phoneArrTelHtml(phoneArr) {

  let formattedPhones = [];
  if (phoneArr && phoneArr.length > 0) {

    phoneArr.map((phone, index) => {
      let formattedPhone = formatPhone(phone,[],true);

      if (!phone.ext) {
        phone.ext = 0;
      }
      let commaSeparator = ', ';
      if (index + 1 === phoneArr.length) {
        commaSeparator = '';
      }
      const phoneNumber = (
        <span>
          <a href={`tel:` + phone.ext + phone.phone}>
            {formattedPhone}
          </a>
          {commaSeparator}
        </span>
      );
      formattedPhones.push(phoneNumber);
    });
  }
  return formattedPhones;
}

export const formatPhoneArrayHTML= (phoneArr, includeExt=true)=> {
 let phoneFormattedForCall = phoneArr?.map(phone=>formatPhoneForCall(phone));
 let phoneFormatted = phoneArr?.map(phone=>formatPhone(phone,[],includeExt));
   let phn = ', ';
  let phnArr = [];

  if (phoneArr && phoneArr.length > 0) {
    let phnB = '';
    phoneArr.map((item, i) => {
      if (parseInt(i) + 1 == phoneArr.length) {
        phn = '';
      }
      if (item.phone !== 'undefined' && item.phone !== '') {
        if (item.ext) {
          phnB +=
            item.type +
            ': ' +
            
            '<a href="tel:' +
            phoneFormattedForCall[i] +
            '">' +
            phoneFormatted[i] +
            phn +
            '</a><br/>';
        } else {
          phnB +=
            item.type +
            ': ' +
            '<a href="tel:' +
            phoneFormattedForCall[i] +
            '">' +
            phoneFormatted[i] +
            phn +
            '</a><br/>';
        }
      }
    });
    phnArr.push(phnB);
    return phnArr;
  }
}

export const phoneNumberFunWithHtml=(phoneNum)=> {
  let phn = ', ';
  let phnB = '';
  if (phoneNum && phoneNum.length > 0) {
    phoneNum.map((item, i) => {
      if (parseInt(i) + 1 == phoneNum.length) {
        phn = '';
      }
      if (item.phone !== 'undefined' && item.phone !== '') {
        if (item.ext) {
          phnB += item.type + ': ' + '(' + item.ext + ') ' + item.phone + phn + '\n';
        } else {
          phnB += item.type + ': ' + +item.phone + phn + '\n';
        }
      }
    });
    return phnB;
  }
}